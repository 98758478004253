import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify.js';
import { auth, db } from './firebase';
import { firestorePlugin } from 'vuefire';

Vue.config.productionTip = false;
Vue.use(firestorePlugin, { maxRefDepth: 0 });

let app;
let userProfileListener;
auth.onAuthStateChanged((currentUser) => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
    Vue.prototype.$userProfile = null;
  }
  if (currentUser) {
    if (userProfileListener) userProfileListener = null;
    userProfileListener = db
      .doc(`users/${currentUser.uid}`)
      .onSnapshot((userProfile) => {
        if (typeof userProfile === 'object')
          Vue.prototype.$userProfile = { ...userProfile.data() };
        Vue.prototype.$userProfile = userProfile.data();
      });
  }
});

// new Vue({
//   router,
//   vuetify,
//   render: (h) => h(App),
// }).$mount('#app');
