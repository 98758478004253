<template>
  <v-app class="app">
    <v-main class="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>
<style lang="scss">
$body-font-family: 'Poppins', sans-serif;
@import '~vuetify/src/styles/styles.sass';

.v-application,
.v-application .main {
  font-family: $body-font-family, sans-serif !important;
  .title {
    font-family: $body-font-family, sans-serif !important;
  }

  .vBtn,
  .v-btn {
    text-transform: none;
    letter-spacing: normal;
  }
}

html {
  overflow: auto !important;
}

html,
body {
  min-height: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
}

i {
  // font-family: 'Material Icons';
}
</style>
