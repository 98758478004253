import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '@/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin/newDebit',
    name: 'AdminNewDebitLanding',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaignCouponNewDebitLanding" */ '../views/admin/NewDebit/Landing'
      ),
    alias: '/d',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/newDebit/:id',
    name: 'AdminNewDebit',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaignCouponNewDebit" */ '../views/admin/NewDebit'
      ),
    alias: '/d/:id',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/campaigns/:campaignid/coupons/new',
    name: 'AdminCampaignNewCoupon',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaignNewCoupon" */ '../views/admin/Campaigns/Campaign/Coupon/New'
      ),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin/campaigns/:campaignid/coupons/:couponid',
    name: 'AdminCampaignCoupon',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaignCoupon" */ '../views/admin/Campaigns/Campaign/Coupon'
      ),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin/campaigns/:campaignid',
    name: 'AdminCampaign',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaign" */ '../views/admin/Campaigns/Campaign'
      ),
    meta: {
      requiresAuth: 'admin',
    },
    children: [
      {
        path: 'coupons',
        name: 'AdminCampaignCoupons',
      },
    ],
  },
  {
    path: '/admin/campaigns',
    name: 'AdminCampaigns',
    component: () =>
      import(
        /* webpackChunkName: "adminCampaigns" */ '../views/admin/Campaigns'
      ),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin/users/new',
    name: 'AdminNewUser',
    component: () =>
      import(
        /* webpackChunkName: "adminNewUser" */ '../views/admin/Users/User/New'
      ),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin/users/:userid',
    name: 'AdminUser',
    component: () =>
      import(/* webpackChunkName: "adminUser" */ '../views/admin/Users/User'),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: () =>
      import(/* webpackChunkName: "adminUsers" */ '../views/admin/Users'),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/admin',
    name: 'AdminOverview',
    component: () =>
      import(/* webpackChunkName: "adminOverview" */ '../views/admin/Overview'),
    meta: {
      requiresAuth: 'admin',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/admin/Login'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:privatecouponid',
    name: 'Coupon',
    component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon'),
  },
  {
    path: '/',
    name: 'Landing',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/Landing'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth === true);
  const requiresNotAuth = to.matched.some((x) => x.meta.requiresAuth === false);
  const requireAdmin = to.matched.some((x) => x.meta.requiresAuth === 'admin');

  if (auth.currentUser && !router.app.$userProfile)
    while (!router.app.$userProfile)
      await new Promise((resolve) => setTimeout(resolve, 10));

  if (requireAdmin) {
    if (!auth.currentUser) return next(`/login?redirect=${to.path}`);

    if (router.app.$userProfile.admin !== true) {
      return next('/d/?error=requiresAdminPermission');
    }
  }
  if (requiresAuth) {
    if (!auth.currentUser) return next(`/login?redirect=${to.path}`);
  }
  if (requiresNotAuth && auth.currentUser) {
    return next('/admin');
  }

  next();
});

export default router;
