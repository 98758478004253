import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyC6TStArww98NYV0MsGElainnwC0sJbbLo',
  authDomain: 'fhgnews-coupons.firebaseapp.com',
  databaseURL: 'https://fhgnews-coupons.firebaseio.com',
  projectId: 'fhgnews-coupons',
  storageBucket: 'fhgnews-coupons.appspot.com',
  messagingSenderId: '872389436060',
  appId: '1:872389436060:web:ab7ac653d934b760838359',
  measurementId: 'G-1QBWMGR93D',
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const func = firebase.functions();

const { Timestamp, GeoPoint } = firebase.firestore;

// export utils/refs
export { db, auth, func, Timestamp, GeoPoint };
